body {
    margin: 0px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.extra {
    margin: 0px 20px;
}

/* Nav */

.nav {
    z-index: 90;
    background: linear-gradient(rgb(240, 130, 100), rgb(240, 90, 60));
    width: 100%;
}

#nav-logo {
    z-index: 100;
    width: 80px;
    padding: 0px;
}

.menu-options {
    color: white;
}

.navbar-dark .navbar-nav .nav-link {
    color: white;
}
.navbar-dark .navbar-nav .nav-link:hover {
    color: white;
}
.navbar-dark .navbar-brand {
    color: white;
}
.navbar-dark .navbar-brand:hover {
    color: white;
}
.nav .navbar .navbar-expand-lg .navbar-dark {
    padding: 0px;
}
.mr-auto, .mx-auto {
    margin-left: auto!important;
    margin-right: 0px!important;
}
.navbar-dark .navbar-toggler {
    border: none;
}

/* Main Content */

#main-image {
    padding-top: 20px;
    width: 90%;
    max-width: 1080px;
}

.group-margin {
    margin: 70px auto;
    max-width: 900px
}

.text-box {
    margin: auto;
    max-width: 600px;
}

.text-credit {
    text-align: left;
    font-size: 12px;
    padding: 0 1rem;
}

strong {
    font-size: 18px;
}

hr {
    width: 50%
}

.viz-images {
    width: 40%;
    max-width: 350px;
    padding: 10px 25px 25px 10px;
}

.portfolio-images {
    position: relative;
    object-fit: contain;
    width: 200px;
    height: 200px;
    margin: 10px;
    border: solid 1px lightgrey;
}

.portfolio-images:hover {
    border: solid 1px grey;
    cursor: pointer;
    transition: 0.3s;
    transform: scale(1.05);
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
}

.modal-image {
    max-width: 100%;
    max-height: 70vh;
    margin: auto;
}

.modal-content {
    width: unset;
    margin: auto;
}

#chart {
    width: 85%;
}

.btn-link,
.btn-link:hover,
.btn-link:focus {
    color: #212529;
    text-decoration: none;
}

.btn-link strong {
    display: flex;
    align-items: center;
}

.trigger {
    margin-left: 8px;
    font-size: 12px;
    transition: all 200ms;
}

.trigger-rotated {
    margin-left: 8px;
    margin-bottom: 2px;
    font-size: 12px;
    transform: rotate(180deg);
    transition: all 200ms;
}

/* Footer */

i {
    font-size: 24px
}

#footer {
    background: linear-gradient(white, lightgrey);
    width: 100%;
    color: black;
    padding: 10px 0px;
    font-size: 12px;
    border-top: 1px solid lightgrey;
}

a {
    line-height: 2;
    font-size: 16px;
    padding: 5px;
    text-align: left;
    color:white;
}

a:hover {
    color: white;
    background-color: rgb(240, 130, 100);
}

.links {
    font-size: 24px;
    color: black;
    padding: 20px 20px 0px 20px;
}

.links:hover {
    color: black;
    background: none;
}

/* Media Queries */

@media only screen and (max-width: 650px) {
    .viz-images {
        display: block;
        margin: auto;
        min-width: 225px;
    }
}